import ButtonLoader, {
  ErrorLoader,
} from "../../../../../../UI/Loaders/Loaders";
import { formatMoney } from "../../../../../../helpers/formatter";
import {
  repaymentFrequencys,
  scheduleType,
} from "../../../../../../models/product-data-model";
import { IDisbursementSummary } from "../../interface";
import { Button, Space, Switch } from "antd";
import useMoratoriumTypes from "../../../../../../custom-hooks/useMoratoriumTypes";
import useDisbursementChannels from "../../../../../../custom-hooks/useDisbursementChannels";
import useDisbursementChannelAccount from "../../../../../../custom-hooks/useDisbursementChannelAccount";
import { ReactComponent as PlusIcon } from "../../../../../../assets/svg/icons/plus-round.svg";
import { ReactComponent as MinusIcon } from "../../../../../../assets/svg/icons/minus-round.svg";
import { useForm } from "react-hook-form";
import DisbursementConfirmationModal from "./DisbursementConfirmationModal";
import styles from "./DisbursementSummary.module.scss";
import closeCircle from "../../../../../../assets/icons/fontawesome/svgs/regular/close-circle.svg";
import successTick from "../../../../../../assets/icons/fontawesome/svgs/regular/tick-circle.svg";
import LoanOfferModal from "../../../../LoanOffer/LoanOffer";
import { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Alert from "../../../../../../NewComponents/TypedComponents/Alert/Alert.component";
import { MixPanel } from "../../../../../../shared/MixPanel/MixPanel";
import EquityContribution from "../../../../EquityContribution/EquityContribution";
import { useAllCustomerWallet } from "../../../../../../custom-hooks/useAllCustomerWallet";
import CustomerWalletTransactions from "../../../CustomerWallet/CustomerWalletTrasactions";
import { WalletDetails } from "../../../../../../interfaces/individualCustomer";

const DisbursementSummary = ({
  first_name,
  last_name,
  currency,
  state,
  loan_amount,
  loan_id,
  handleOptionSelect,
  disburseLoan,
  handleGenerateLoanSchedule,
  handleSaveLoanDetails,
  showDisbursementConfirmationModal,
  setshowDisbursementConfirmationModal,
  loanScheduleResponse,
  saveLoanDetailsResponse,
  switchState,
  setSwitchState,
  product_name,
  interest_rate,
  corebanking_tenant,
  product_variant,
  disbursementLoading,
  sendNewLoanOffer,
  closeNewLoanOfferTrigger,
  triggerNewLoanOffer,
  loanOfferState,
  isLoadingLoanOffers,
  loan_offers,
  require_equity_contribution,
  equity_contribution_amount,
  equity_contribution_amount_paid,
  total_cost_amount,
  cost_discount_percentage,
  equity_contribution_percentage,
  description,
  account_number,
  account_name,
  wallet_id,
  consumer_type,
  wallet_provider_id,
  target_id,
  tenant_id,
  customer_id,
  loan_ref,
}: IDisbursementSummary) => {
  const [showMoreSettings, setShowMoreSettings] = useState(false);
  const [showLoanOffer, setShowLoanOffer] = useState(false);
  const [showEquityContribution, setShowEquityContribution] = useState(false);
  const { data: moratoriumTypes } = useMoratoriumTypes();
  const { data: disbursementChannels } = useDisbursementChannels();
  const { data: disbursementChannelAccount } = useDisbursementChannelAccount(
    state?.disbursement_channel_id,
  );
  const [viewTransactions, setViewTransactions] = useState(false);
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    consumerType: "Customer",
    customerId: String(customer_id),
  });

  const {
    data: allWallets,
    isLoading: allWalletIsLoading,
    error,
  } = useAllCustomerWallet(preState);

  const [isError, setIsError] = useState(false);
  const handleSwitchChange = (checked: any) => {
    setSwitchState(checked);
  };
  const { register, setValue } = useForm({});
  useEffect(() => {
    setValue("currency", currency);
    setValue("product", product_name);
    setValue("interestRate", interest_rate);
    setValue("coreBankingTenant", corebanking_tenant);
    setValue("productVariant", product_variant);

    //eslint-disable-next-line
  }, [
    product_name,
    interest_rate,
    product_variant,
    corebanking_tenant,
    currency,
  ]);

  useEffect(() => {
    MixPanel.track(`Page View: DisbursementSummary`, {
      page: "DisbursementSummary",
    });
  }, []);

  const walletDetails = useMemo(() => {
    return allWallets?.data?.find(
      (transaction: WalletDetails) =>
        transaction.accountNumber === state?.account_number,
    );
  }, [allWallets, state?.account_number]);

  const handleSendNewOffer = () => {
    setIsError(false);
    if (!state.comment) {
      setIsError(true);
    } else {
      Swal.fire({
        icon: "warning",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        showCancelButton: true,
        confirmButtonText: "Yes, send it!",
        cancelButtonText: "No, cancel!",
      }).then((result) => {
        if (result.isConfirmed) {
          sendNewLoanOffer();
        }
      });
    }
  };

  return (
    <div className={styles.disbursement_container}>
      {loanOfferState.error && (
        <Alert type="danger" message={`${loanOfferState.error}`} />
      )}

      {!isLoadingLoanOffers &&
        loan_offers?.length > 0 &&
        loan_offers?.some((offer) => offer.status === "Pending") && (
          <div className={styles.awaiting_customer_loan_offer_acceptance}>
            <span className={styles.awaiting_customer_loan_offer_text}>
              Awaiting customer new offer acceptance
            </span>
          </div>
        )}
      <h3 className="Modal__title p-b-1">Disbursement Summary</h3>
      {/* 2. Confirm Update Role Modal*/}
      <div id="disbursement-confirm">
        <div className="m-b-30">
          <header className={styles.disbursement_summary_header}>
            <div>
              <h5>Are you sure you want to disburse the following loan? </h5>
              <p>Borrower Name: {`${first_name} ${last_name}`}</p>
              <p>Loan Amount: {formatMoney(loan_amount)}</p>
            </div>

            <div className={styles.view_btn_container}>
              {require_equity_contribution && (
                <Button
                  className={styles.view_equity_contribution}
                  onClick={() => setShowEquityContribution(true)}
                >
                  View Equity Contribution
                </Button>
              )}
              {product_variant === "Embed" && (
                <Button
                  className={styles.view_offer_btn}
                  onClick={() => setShowLoanOffer(true)}
                >
                  View Offer
                </Button>
              )}
            </div>
          </header>
          <form>
            {state?.error && (
              <p className="text-danger">Field cannot be blank</p>
            )}
            <div className="disburseSummary">
              <label style={{ width: "100%" }}>
                Currency
                <input
                  className="form-control"
                  name="currency"
                  ref={register}
                  readOnly
                />
              </label>
              <label style={{ width: "100%" }}>
                Product
                <input
                  className="form-control"
                  name="product"
                  ref={register}
                  readOnly
                />
              </label>
              <label style={{ width: "100%" }}>
                Interest Rate
                <input
                  className="form-control"
                  name="interestRate"
                  ref={register}
                  readOnly
                />
              </label>
              <label style={{ width: "100%" }}>
                Core Banking Tenant
                <input
                  className="form-control"
                  name="coreBankingTenant"
                  ref={register}
                  readOnly
                />
              </label>
              <label style={{ width: "100%" }}>
                Product Variant <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="productVariant"
                  ref={register}
                  readOnly
                />
              </label>

              <label style={{ width: "100%" }}>
                Approved Amount <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="amountApproved"
                  value={state?.amountApproved}
                  onChange={(e) => handleOptionSelect(e)}
                  readOnly={product_variant === "Spark" ? true : false}
                />
              </label>
              <label style={{ width: "100%" }}>
                Approved Tenor <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="approvedTenor"
                  value={state?.approvedTenor}
                  onChange={(e) => handleOptionSelect(e)}
                  readOnly={product_variant === "Spark" ? true : false}
                />
              </label>
              <label style={{ width: "100%" }}>
                Requested Amount <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="requestedAmount"
                  value={state?.requestedAmount}
                  onChange={(e) => handleOptionSelect(e)}
                  readOnly={product_variant === "Embed" ? true : false}
                />
              </label>
              <label style={{ width: "100%" }}>
                Requested Tenor <span style={{ color: "red" }}>*</span>
                <input
                  className="form-control"
                  name="requestedTenor"
                  value={state?.requestedTenor ? state?.requestedTenor : ""}
                  onChange={(e) => handleOptionSelect(e)}
                  readOnly={product_variant === "Embed" ? true : false}
                />
              </label>
              {/* selectedFunder temporarily removed, there is no available business use case currently */}

              {/* <label style={{ width: "100%" }}>
                Funder
                <select
                  className="round user__form form-control"
                  name="selectedFunder"
                  id="selectedFunder"
                  value={state?.selectedFunder}
                  onChange={(e) => handleOptionSelect(e)}
                >
                  <option selected></option>
                  {funderOptions}
                </select>
              </label> */}

              <label style={{ width: "100%" }}>
                Comment
                <input
                  className="form-control"
                  name="comment"
                  value={state?.comment}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>

              <label style={{ width: "100%" }}>
                Effective Date <span style={{ color: "red" }}>*</span>
                <input
                  className="round user__form form-control"
                  name="selectedDate"
                  type="date"
                  id="selectedDate"
                  value={state?.selectedDate}
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>

              <div
                style={{
                  display: "flex",
                  // alignItems: "center",
                  gap: "10px",
                  width: "100%",
                }}
              >
                <label>
                  Select Account
                  <select
                    // disabled={product_variant === "Embed" ? true : false}
                    className="form-control"
                    name="account_number"
                    value={state?.account_number}
                    onChange={(e) => handleOptionSelect(e)}
                  >
                    <option value="">Select an account</option>
                    {allWallets &&
                      allWallets.data?.map((wallet: WalletDetails) => (
                        <option key={wallet?.id} value={wallet?.accountNumber}>
                          {wallet?.accountName} - {wallet?.accountNumber}
                        </option>
                      ))}
                  </select>
                </label>
                <button
                  type="button"
                  data-id={loan_id}
                  onClick={() => {
                    setViewTransactions((prev) => !prev);
                  }}
                  disabled={!state?.account_number}
                  className="btn zoom-element login-btn"
                  style={{ height: "3rem", marginBottom: "8px" }}
                >
                  {`${viewTransactions ? "Hide Transactions" : "View Transaction"}`}
                </button>
              </div>

              <label style={{ width: "100%" }}>
                First Repayment Date
                <input
                  className="round user__form form-control"
                  name="firstRepaymentDate"
                  type="date"
                  id="firstRepaymentDate"
                  value={
                    state?.firstRepaymentDate ? state?.firstRepaymentDate : ""
                  }
                  onChange={(e) => handleOptionSelect(e)}
                />
              </label>
            </div>
            <div>
              <label style={{ width: "100%", marginTop: "10px" }}>
                Is Credit Bureau Check Done?
              </label>
              <Space direction="vertical">
                <Switch
                  checkedChildren="Yes"
                  unCheckedChildren="No"
                  checked={switchState}
                  onChange={handleSwitchChange}
                />
              </Space>
            </div>
            <div className="mt-4">
              {triggerNewLoanOffer &&
              (loan_offers?.length === 0 ||
                loan_offers?.some((offer) => offer.status !== "Pending")) ? (
                <div className={styles.new_loan_offer_trigger_container}>
                  <img
                    src={closeCircle}
                    alt=""
                    className={styles.close_btn}
                    onClick={closeNewLoanOfferTrigger}
                  />
                  <span className={`${styles.new_offer_trigger_text}`}>
                    Would you like to send this as a loan offer to the user
                  </span>
                  <Button
                    className={styles.new_loan_offer_trigger_action_btn}
                    onClick={handleSendNewOffer}
                    loading={loanOfferState.isLoading}
                    disabled={
                      isLoadingLoanOffers ||
                      loanOfferState.isLoading ||
                      (loan_offers?.length > 0 &&
                        loan_offers?.some(
                          (offer) => offer.status === "Pending",
                        ))
                    }
                  >
                    {isLoadingLoanOffers
                      ? "Please wait.."
                      : loanOfferState.isLoading
                        ? "Processing..."
                        : "Yes"}
                  </Button>
                </div>
              ) : null}

              {loanOfferState.success && (
                <div className={styles.new_loan_offer_success_msg_container}>
                  <img src={successTick} alt="" />
                  <span className={styles.new_offer_success_text}>
                    Loan offer has been sent to this user.
                  </span>
                </div>
              )}
            </div>

            {/* More Settings */}
            <div>
              <div className="d-flex justify-content-between align-items-center py-2">
                <h4>More Settings</h4>
                <div></div>
              </div>
              <small
                className="text-muted cursor-pointer"
                onClick={() => {
                  setShowMoreSettings(!showMoreSettings);
                }}
              >
                {showMoreSettings ? (
                  <>
                    Click here <MinusIcon /> to hide additional settings
                  </>
                ) : (
                  <>
                    Click here <PlusIcon /> to add additional settings to the
                    existing template
                  </>
                )}
              </small>

              {showMoreSettings && (
                <div className="row">
                  {/* Start of disbursement channel */}
                  <div className="col-6 my-2">
                    <label>Disbursement Channel</label>
                    <div className="form-group ">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="disbursement_channel_id"
                        value={state?.disbursement_channel_id}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">Select Disbursement Channel</option>
                        {disbursementChannels &&
                          disbursementChannels?.data?.data.map(
                            (_disbursementChannel) => (
                              <option
                                key={_disbursementChannel?.id}
                                value={_disbursementChannel?.id}
                              >
                                {_disbursementChannel?.channel_name}
                              </option>
                            ),
                          )}
                      </select>
                    </div>
                  </div>

                  <div className="col-6  my-2">
                    <label>Disbursement Channel Accounts</label>
                    <div className="form-group ">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="disbursement_channel_account_id"
                        value={state?.disbursement_channel_account_id}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">
                          Select Disbursement Channel Account
                        </option>
                        {disbursementChannelAccount &&
                          disbursementChannelAccount?.data?.data.map(
                            (_channelAccount) => (
                              <option
                                key={_channelAccount?.id}
                                value={_channelAccount?.id}
                              >
                                {_channelAccount?.bank_name}/
                                {_channelAccount?.account_number}/
                                {_channelAccount?.account_name}
                              </option>
                            ),
                          )}
                      </select>
                    </div>
                  </div>
                  {/* end of disbursement channel */}

                  <div className="col-6  my-2">
                    <label>Moratorium Type</label>
                    <div className="form-group ">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="moratorium_type_id"
                        value={state?.moratorium_type_id}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">Select Moratorium Type</option>
                        {moratoriumTypes &&
                          moratoriumTypes.data?.data.map((_moratoriumType) => (
                            <option
                              key={_moratoriumType?.id}
                              value={_moratoriumType?.id}
                            >
                              {_moratoriumType?.name}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-6  my-2">
                    <label>Amortization Type</label>
                    <div className="form-group inputWithIcon">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="amortization_type_id"
                        value={state?.amortization_type_id}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">Select Amortization type</option>
                        <option value={0}>Equal Principal Payments</option>
                        <option value={1}>Equal Installments</option>
                      </select>
                    </div>
                  </div>

                  <div className="col-6  my-2">
                    <label>Repayment Type</label>
                    <div className="form-group ">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="repayment_type"
                        value={state?.repayment_type}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">Select Repayment Type</option>
                        {repaymentFrequencys.map((_repaymentFrequency) => (
                          <option
                            key={_repaymentFrequency?.repayment_Type}
                            value={_repaymentFrequency?.repayment_Type}
                          >
                            {_repaymentFrequency?.repaymentFrequencyName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="col-6  my-2">
                    <label>Interest Type </label>
                    <div className="form-group ">
                      <select
                        disabled={product_variant === "Embed" ? true : false}
                        className="round user__form form-control"
                        name="interest_type_id"
                        value={state?.interest_type_id}
                        onChange={(e) => handleOptionSelect(e)}
                      >
                        <option value="">Select Interest type</option>
                        {scheduleType.map((_item) => (
                          <option
                            key={_item?.schedule_Type}
                            value={_item?.schedule_Type}
                          >
                            {_item?.scheduleTypeName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              )}
              {showDisbursementConfirmationModal && (
                <DisbursementConfirmationModal
                  disburseLoan={disburseLoan}
                  setshowDisbursementConfirmationModal={
                    setshowDisbursementConfirmationModal
                  }
                  disbursementLoading={disbursementLoading}
                />
              )}
            </div>

            <div className="Form__action-buttons m-t-2">
              <div>
                {product_variant === "Spark" && (
                  <button
                    data-id={loan_id}
                    onClick={(e) => handleSaveLoanDetails(e)}
                    disabled={
                      !(
                        state?.comment &&
                        state?.selectedDate &&
                        state?.requestedAmount &&
                        state?.requestedTenor
                      )
                    }
                    className="btn zoom-element login-btn m-r-1"
                  >
                    <i className="fa fa-save" /> Save
                    {saveLoanDetailsResponse?.status === "loading" && (
                      <ButtonLoader />
                    )}
                  </button>
                )}

                <button
                  type="button"
                  data-id={loan_id}
                  onClick={handleGenerateLoanSchedule}
                  disabled={
                    !(
                      state?.selectedDate &&
                      state?.requestedAmount &&
                      state?.requestedTenor
                    )
                  }
                  className="btn zoom-element btn login-btn m-r-1"
                >
                  <i className="fa fa-clock" /> Generate Loan Schedule
                  {loanScheduleResponse?.status === "loading" && (
                    <ButtonLoader />
                  )}
                </button>

                <button
                  type="button"
                  data-id={loan_id}
                  onClick={() => {
                    setshowDisbursementConfirmationModal(true);
                  }}
                  disabled={
                    !(
                      state?.comment &&
                      state?.selectedDate &&
                      state?.requestedAmount &&
                      state?.requestedTenor
                    )
                  }
                  className="btn zoom-element login-btn  m-r-1"
                >
                  <i className="fa fa-check-circle" /> Proceed
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* 2. Disbursement Loader*/}
      <div
        id="disbursement-loader"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <div className=" form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading" id="adv-loader-alt" />
            <div className="center-txt webapp-ripple-text">
              <i className="notifier fas fa-user-cog" />
              Processing ...
            </div>
          </div>
        </div>
      </div>

      {/* 3. Disbursement Success Message*/}
      <div
        id="disbursement-success"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <div className="form-loader form-loader-login verify">
          <div className="centerLoader">
            <div className="loading success-loader">
              <i className="webapp-ripple-notification success-text fa fa-check-circle" />
            </div>
            <div className="center-txt webapp-ripple-text">
              <i className="notifier far fa-check-circle" />
              Disbursement Initiated
            </div>
          </div>
        </div>
      </div>

      {/* 4. Disbursement Error */}
      <div
        id="disbursement-error"
        className="hidden loading-overlay speed-1x fadeInRight"
      >
        <ErrorLoader
          icon="fas fa-wifi"
          text="Network Issue"
          subText="Unable to connect to Advancly services."
        />
      </div>
      {showLoanOffer && (
        <LoanOfferModal
          setShowLoanOffer={setShowLoanOffer}
          loan_offers={loan_offers}
          isLoadingLoanOffers={isLoadingLoanOffers}
        />
      )}
      {showEquityContribution && (
        <EquityContribution
          setShowEquityContribution={setShowEquityContribution}
          require_equity_contribution={require_equity_contribution}
          equity_contribution_amount={equity_contribution_amount}
          equity_contribution_amount_paid={equity_contribution_amount_paid}
          total_cost_amount={total_cost_amount}
          cost_discount_percentage={cost_discount_percentage}
          equity_contribution_percentage={equity_contribution_percentage}
          description={description}
          account_number={account_number}
          account_name={account_name}
          wallet_id={wallet_id}
          consumer_type={consumer_type}
          wallet_provider_id={wallet_provider_id}
          target_id={target_id}
          tenant_id={tenant_id}
          loan_ref={loan_ref}
        />
      )}

      {viewTransactions && (
        <CustomerWalletTransactions walletDetails={walletDetails} />
      )}
    </div>
  );
};

export default DisbursementSummary;
