import { useQuery } from "react-query";
import * as ajaxEndpoints from "../api/ajax-endpoints";
import { getData } from "../newApis/apiMethods";

interface IWallet {
  AccountNumber: string;
  WalletId: string;
  ConsumerType: string;
  WalletProviderId: number;
  TenantId: string | number | null;
}

async function getWalletInfo({
  AccountNumber,
  WalletId,
  ConsumerType,
  WalletProviderId,
  TenantId,
}: IWallet) {
  const response = await getData(
    `${ajaxEndpoints.GET_CUSTOMER_WALLET_INFO}?AccountNumber=${AccountNumber}&WalletId=${WalletId}&ConsumerType=${ConsumerType}&WalletProviderId=${WalletProviderId}&TenantId=${TenantId}`,
  );

  return response.data;
}

export function useFetchWalletInfo({
  AccountNumber,
  WalletId,
  ConsumerType,
  WalletProviderId,
  TenantId,
}: IWallet) {
  return useQuery(
    [
      {
        AccountNumber,
        WalletId,
        ConsumerType,
        WalletProviderId,
        TenantId,
      },
      "getWalletInfo",
    ],
    getWalletInfo,
    {
      enabled: true,
    },
  );
}
