import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import Paginator from "../../../../components/Pagination/Pagination.component";
import { useAllCustomerWallet } from "../../../../custom-hooks/useAllCustomerWallet";
import Loader from "../../../../NewComponents/Loader/Loader.component";
import NoTableDataAvailable from "../../../../NewComponents/NoTableDataAvailable/NoTableDataAvailable.component";
import { showModal } from "../../../../redux/actions/modal";
import CustomerWalletTransactions from "./CustomerWalletTrasactions";
import { MixPanel } from "../../../../shared/MixPanel/MixPanel";

const CustomerWallet = ({ dataRecord }) => {
  const [preState, setPreState] = useState({
    pageNumber: 1,
    size: 20,
    consumerType: "Customer",
    customerId: dataRecord.customer_id,
  });
  const [sendWalletDetails, setSendWalletDetails] = useState("");

  const [count, setCount] = useState(null);
  const [viewTransactions, setViewTransactions] = useState(false);

  const dispatch = useDispatch();

  const { data, status, error } = useAllCustomerWallet(preState);
  if (data) {
    if (count === null) {
      setCount(data?.totalCount);
    }
  }

  useEffect(() => {
    MixPanel.track(`Page View: CustomerWallet.tsx`, {
      page: "CustomerWallet.tsx",
    });
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    dispatch(
      showModal({
        modalIdentifier: "wallet",
        action: "borrowerwallet",
        dataRecord: dataRecord.customer_id,
      }),
    );
  };

  return (
    <div>
      {error && <p className="alert alert-danger small">{error}</p>}
      {!error && (
        <div>
          <div className="">
            {status === "loading" ? (
              <div className="d-flex justify-content-center mt-2 mb-2">
                <Loader />
              </div>
            ) : (
              <div className="pl-3 pr-3">
                <div className="d-flex justify-content-end mb-3">
                  <button
                    className="btn advancly-white-btn btn-md m-0"
                    onClick={handleClick}
                  >
                    Create Wallet
                  </button>
                </div>

                <div className="table-responsive">
                  <table className="table">
                    <thead className="bg-lighter-grey">
                      <tr>
                        <th>S/N</th>
                        <th>Wallet ID</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Bank Name</th>
                        <th>Currency</th>
                        <th>Is Wallet Default</th>

                        <th>Action</th>
                      </tr>
                    </thead>

                    <tbody>
                      {data?.data ? (
                        data?.data?.map((request, _id) => {
                          const {
                            accountNumber,
                            bankName,
                            currency,
                            walletId,
                            accountName,
                            isDefault,
                          } = request;

                          console.log({ request });
                          return (
                            <tr key={_id}>
                              <td>{_id + 1}</td>
                              <td>{walletId}</td>
                              <td>{accountName}</td>
                              <td>{accountNumber}</td>
                              <td>{bankName}</td>
                              <td>{currency}</td>
                              <td>{isDefault ? "True" : "False"}</td>
                              {/* <td>{walletProvider}</td> */}

                              <td>
                                <div
                                  className="color-blue cursor-pointer"
                                  onClick={() => {
                                    setSendWalletDetails(request);
                                    setViewTransactions((view) => !view);
                                  }}
                                >
                                  {!viewTransactions
                                    ? " View Details"
                                    : "Hide Details"}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoTableDataAvailable />
                      )}
                    </tbody>
                  </table>
                </div>
                {data && data?.data && (
                  <Paginator
                    size={preState.size}
                    page={preState.pageNumber}
                    count={count}
                    changeCurrentPage={(pageNumber) =>
                      setPreState({ ...preState, pageNumber })
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {viewTransactions && (
        <CustomerWalletTransactions walletDetails={sendWalletDetails} />
      )}
    </div>
  );
};

export default CustomerWallet;
