import React, { useEffect } from "react";
import { MixPanel } from "../../../shared/MixPanel/MixPanel";
import { useFetchWalletInfo } from "../../../custom-hooks/useFetchCustomerWalletInfo";

const EquityContributionModal = (props: any) => {
  const {
    equity_contribution_amount,
    equity_contribution_amount_paid,
    equity_contribution_percentage,
    total_cost_amount,
    require_equity_contribution,
    cost_discount_percentage,
    account_number,
    account_name,
    wallet_id,
    consumer_type,
    wallet_provider_id,
    tenant_id,
  } = props.details;
  useEffect(() => {
    MixPanel.track(`Page View: EquityContribution.tsx`, {
      page: "EquityContribution.tsx",
    });
  }, []);

  const { data: walletInfo } = useFetchWalletInfo({
    AccountNumber: account_number,
    WalletId: wallet_id,
    WalletProviderId: wallet_provider_id,
    ConsumerType: consumer_type,
    TenantId: tenant_id,
  });

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Item Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                total_cost_amount
                  ? new Intl.NumberFormat("en-US").format(total_cost_amount)
                  : "Unavailable"
              }
            />
            <i className="fas fa-money-bill-wave" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Account Name</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={account_name || "Unavailable"}
            />
            <i className="fas fa-user" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Equity Percentage</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={equity_contribution_percentage || "Unavailable"}
            />
            <i className="fas fa-percentage" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Account Number</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={account_number || "Unavailable"}
            />
            <i className="fas fa-credit-card" />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-6">
          <label className="label-txt">Equity Amount</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              readOnly
              className="user__form form-control"
              value={
                equity_contribution_amount
                  ? new Intl.NumberFormat("en-US").format(
                      equity_contribution_amount,
                    )
                  : "Unavailable"
              }
            />
            <i className="fas fa-coins" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Customer Wallet Balance</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={
                walletInfo?.availableBalance
                  ? new Intl.NumberFormat("en-US").format(
                      walletInfo?.availableBalance,
                    )
                  : "Unavailable"
              }
            />
            <i className="fas fa-wallet" />
          </div>
        </div>
        <div className="col-xs-6">
          <label className="label-txt">Discount Applied</label>
          <div className="form-group inputWithIcon">
            <input
              type="text"
              className="user__form form-control"
              readOnly
              value={cost_discount_percentage || "Unavailable"}
            />
            <i className="fas fa-tag" />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EquityContributionModal;
